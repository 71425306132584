<template>
  <div class="w-2/3 mx-auto my-5 py-5 bg-white rounded-md shadow-md">
    <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        {{ data.name }}
      </h3>
    </div>
    <div
      v-for="(question, idx) in data.quiz_questions"
      :key="question.id"
      v-show="questionId == idx"
    >
      <div class="mx-20 my-5">
        <h2 class="">Question {{ idx + 1 }}</h2>
        <check-multiple-answers
          :type="question.type"
          :question="question.question"
          :answers="question.quiz_replies"
          :mandatory="question.mandatoryReply"
        />
      </div>
      <div v-if="idx < data.quiz_questions.length - 1" class="relative py-10">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center">
          <span
            @click="
              idx < data.quiz_questions.length - 1 ? (questionId = idx + 1) : ''
            "
            class="px-2 text-sm p-1 border rounded-md cursor-pointer body__button"
            >Next</span
          >
        </div>
      </div>
      <div v-else>
        <button
          class="capitalize rounded body__button group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
        >
          {{ $t("finish") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CheckMultipleAnswers from "../../components/CheckMultipleAnswers.vue";
export default {
  props: ["id"],
  components: {
    CheckMultipleAnswers,
  },
  data() {
    return {
      data: {},
      questionId: 0,
    };
  },
  methods: {
    async getGroupData() {
      try {
        const url = `api/v1/training/quiz-groups/${this.id}`;
        const res = await axios.get(`${this.$cookie.getCookie("API")}/${url}`);
        this.data = res.data;
        console.log(
          "file: QuizGroupDetail.vue ~ line 20 ~ getGroupData ~ res.data",
          res.data
        );
      } catch (error) {
        this.errorHandling(error);
      }
    },
  },
  mounted() {
    this.getGroupData();
  },
};
</script>

<style></style>
